<template>
      <div class="w-full allB rounded-10 h-auto bg-white p-6">
        <div class="flex">
          <div class="flex w-1/2">
            <div class="flex items-center w-2/5">
              <div class="w-2 h-10 diviser1"/>
              <div class="ml-4 text-left">
                <div class="text-c10 text-83">Commerces</div>
                <div class="text-c16 font-c8" v-if="allStat"> {{ allStat.totalCompany }}</div>
              </div>
            </div>

<!--            <div class="flex items-center w-2/5">-->
<!--              <div class="w-2 h-10 diviser2"/>-->
<!--              <div class="ml-4 text-left">-->
<!--                <div class="text-c10 text-83">Clients SFD</div>-->
<!--                <div class="text-c16 font-c8" v-if="allStat"> {{ allStat.totalSfdClient }} </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>

          <div class="w-1/2 flex justify-end">
<!--            <div class="w-2/5 text-left">-->
<!--             <multiselect :option="periode" value-t="Période" iconHeight="20" :icon="icons.calendar"/>-->
<!--            </div>-->

            <multiDate @info="retourDateFilter" class="w-3/5"/>
          </div>
        </div>

        <div
            class="flex justify-center mt-32"
            v-if="charge"
        >
          <easy-spinner
              type="dots"
              color="#64C877"
              size="40"
          />
        </div>

        <div class="mt-4" v-if="allStat">
          <apexchart
            width="100%"
            height="300"
            type="area"
            :options="chartOptions"
            :series="series"
          />
        </div>
      </div>
</template>

<script>
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
// import multiselect from '../../helper/form/multiselect'
import multiDate from '../../helper/form/multipleDate'
import calendar from '../../../assets/icons/calendar.svg'

export default {
  name: "Index",
  components: {
    multiDate,
  },

  props: {
    flow: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        calendar
      },
      charge: true,
      periode: ['Année en cours'],
      allStat: null,
      series: [{
        name: 'Clients SFD',
        data: [1, 10, 28, 31, 22, 19, 40]
      }, {
        name: 'Commerces',
        data: [11, 32, 45, 32, 34, 52, 41]
      }],
      chartOptions: {
        chart: {
          height: 370,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 0,
          },
        },
        xaxis: {
          type: 'string',
          categories: ['JAN', 'FEV', 'MAR', 'AVR', 'MAI', 'JUI', 'JUI', 'AOU', 'SEP', 'OCT', 'NOV', 'DEC'],
        },
        legend: {
          show: false,
        },
        fill: {
          opacity: 1,
          colors: ['#7BD0FF', '#64C877']
        }
      },
      user: null,
      range: {
        start: new Date(2022, 0, 1),
        end: new Date(),
      },
    }
  },

  created () {
    if (this.$store.getters.token !== null) {
      this.user = this.$store.getters.trackUser
      if (this.user.admin.sfdId) {
        this.getSfdBillan(this.user.admin.sfdId)
      } else {
        this.getBillan()
      }
    }

  },

  methods: {
    retourDateFilter(a) {
      this.range = a
      if (this.user.admin.sfdId) {
        this.getSfdBillan(this.user.admin.sfdId)
      } else {
        this.getBillan()
      }
    },

    getBillan () {
      this.charge = true
      const mois = this.range.end.getMonth() + 1
      let jour = this.range.end.getDate() + 2

      const end = mois + '-'+ jour + '-' + this.range.end.getFullYear()

      http.post(apiroutes.baseURL + apiroutes.allStat, {
        typeFilter: 'YEAR',
        endDate: new Date(end),
        startDate: this.range.start
      })
          .then(response => {
            this.allStat = response
            // console.log('bilan')
            // console.log(response)
            this.series[1].data = response.sfdClientStat
            this.series[0].data = response.companyStat
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            window.location.reload()
          })
    },

    getSfdBillan (id) {
      http.post(apiroutes.baseURL + apiroutes.statBySfd, {
        id: id
      })
          .then(response => {
            this.allStat = response
            // console.log('bilan')
            // console.log(response)
            this.series[1].data = response.sfdClientStat
            this.series[0].data = response.companyStat
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            window.location.reload()
          })
    }
  }
}
</script>

<style scoped>
.diviser1{
  width: 2.68px;
  height: 33.91px;
  background: #79E38D;
}

.allB{
  height: 373px;
}

.diviser2{
  width: 2.68px;
  height: 33.91px;
  background: #7BD0FF;
}
</style>
