<template>
  <div
      class="inputo flex items-center pl-2"
      :style="{'height': height, 'border': border, 'border-radius': radius, 'background-color': background}"
  >
    <v-date-picker
        v-model="range"
        mode="date"
        :min-date='minDate'
        :max-date='maxDate'
        :placeholder="placeholder"
        :masks="masks"
        is-range
    >
      <template v-slot="{ inputValue, isDragging, togglePopover }">
        <div class="flex flex-col sm:flex-row justify-start items-center">
          <div class="flex items-center h-full">
            <button
                class="p-2 hover:bg-blue-200 text-blue-600 rounded-l focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                @click="togglePopover()"
            >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  class="w-4 h-4 fill-current"
              >
                <path
                    d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                ></path>
              </svg>
            </button>
            <input
                class="flex-grow pl-1 pr-2 py-1 bg-gray-100 rounded w-full"
                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                :value="inputValue.start"
                placeholder="Début"
                @click="togglePopover()"
            />
          </div>

           <span class="flex-shrink-0 m-2">
              <svg
                  class="w-4 h-4 stroke-current text-gray-600"
                  viewBox="0 0 24 24"
              >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </span>
          <div class="flex items-center h-full">
            <button
                class="p-2 hover:bg-blue-200 text-blue-600 rounded-l focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                @click="togglePopover()"
            >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  class="w-4 h-4 fill-current"
              >
                <path
                    d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                ></path>
              </svg>
            </button>
            <input
                class="flex-grow pl-1 pr-2 py-1 rounded w-full"
                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                :value="inputValue.end"
                placeholder="Fin"
                @click="togglePopover()"
            />
          </div>
        </div>
      </template>
    </v-date-picker>
    {{ sendValue }}
  </div>
</template>

<script>

export default {
  name: 'Index',
  components: {
  },

  props: {
    minDate: {
      type: String,
      default: null
    },

    startDate: {
      type: String,
      default: null
    },

    mode: {
      type: String,
      default: null
    },

    maxDate: {
      type: String,
      default: null
    },

    choseTime: {
      type: Boolean,
      default: true
    },

    format: {
      type: String,
      default: 'dd-MM-yyyy HH:mm'
    },

    placeholder: {
      type: String,
      default: 'Cliquez ici pour selectionner'
    },

    valueT: {
      type: String,
      default: null
    },

    size: {
      type: String,
      default: '14px'
    },
    height: {
      type: String,
      default: '40px'
    },
    border: {
      type: String,
      default: '1px solid #DCDCE4'
    },
    radius: {
      type: String,
      default: '4px'
    },
    background: {
      type: String,
      default: 'white'
    }
  },

  data () {
    return {
      dure: '',
      activeDate: false,
      error: true,

      range: {
        start: new Date(2022, 0, 1),
        end: new Date(),
      },

      temoins: {
        start: new Date(2022, 0, 1),
        end: new Date(),
      },

      masks: {
        input: 'DD-MM-YYYY',
      },
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.range.start !== this.temoins.start || this.range.end !== this.temoins.end) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.temoins = this.range
        this.$emit('info', this.range)
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.error = true
      }
    }
  },

  methods: {
    openDate () {
      this.activeDate = true
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables";
.datePick::v-deep(.dp__input) {
    width: 100%;
    height: 40px;
    background-color: transparent;
    border: none;
}

.input:focus-within {
  border: 1px solid $focus-color!important;
}
.input:hover {
  border: 1px solid $hover-color!important;
}
.error{
  border: 1px solid #ea9f9f!important;
  input{
    color: #ea3636!important;
  }
}
.succe{
  border: 1px solid #5fc187!important;
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .input{
    height: 65px;
    font-size: 18px;
  }
}

@media screen and (max-width: 700px){
  .input{
    height: 55px;
    font-size: 14px;
  }
}

</style>
